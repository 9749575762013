<template>
  <b-dropdown
    v-model="selectedTaskType"
    class="task-type-filter"
    position="is-bottom-left">
    <div slot="trigger">
      <b-input
        :value="selectedTaskType ? $t(`tasks.taskType.${selectedTaskType}`) : $t('tasks.taskType.title')"
        iconRight="chevron-down"
        readonly/>
    </div>
    <b-dropdown-item
      v-for="taskType in taskTypes"
      :key="`task_type_${taskType}`"
      :value="taskType">
      {{ $t(`tasks.taskType.${taskType}`) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'task-type-filter',
  props: {
    value: {
      type: String,
      default: null
    },
    taskTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedTaskType: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.selectedTaskType = this.value
      }
    },
    selectedTaskType() {
      this.$emit('input', this.selectedTaskType)
    }
  }
}
</script>

<style lang="scss" scoped>
.task-type-filter {
  &::v-deep {
    * {
      @extend %body;
    }

    .input {
      max-width: 140px;
      cursor: pointer;
    }
  }
}
</style>
